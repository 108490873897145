@mixin box-shadow($val) {
    -webkit-box-shadow: ($val);
    -moz-box-shadow: ($val);
    -ms-box-shadow: ($val);
    -o-box-shadow: ($val);
    box-shadow: ($val);
}

@mixin border-radius($val) {
    -webkit-border-radius: ($val);
    -moz-border-radius: ($val);
    -ms-border-radius: ($val);
    -o-border-radius: ($val);
    border-radius: ($val);
}

@mixin transition($val) {
    -webkit-transition: ($val);
    -moz-transition: ($val);
    -ms-transition: ($val);
    -o-transition: ($val);
    transition: ($val);
}

@mixin translateX($val) {
    -webkit-transform: translateX($val);
    -moz-transform: translateX($val);
    -ms-transform: translateX($val);
    -o-transform: translateX($val);
    transform: translateX($val);
}

@mixin translateY($val) {
    -webkit-transform: translateY($val);
    -moz-transform: translateY($val);
    -ms-transform: translateY($val);
    -o-transform: translateY($val);
    transform: translateY($val);
}

@mixin translate3d($val, $val, $val) {
    -webkit-transform: translate3d($val, $val, $val);
    -moz-transform: translate3d($val, $val, $val);
    -ms-transform: translate3d($val, $val, $val);
    -o-transform: translate3d($val, $val, $val);
    transform: translate3d($val, $val, $val);
}

@mixin rotate($val) {
    -webkit-transform: rotate($val);
    -moz-transform: rotate($val);
    -ms-transform: rotate($val);
    -o-transform: rotate($val);
    transform: rotate($val);
}

@mixin scale($val) {
    -webkit-transform: scale($val);
    -moz-transform: scale($val);
    -ms-transform: scale($val);
    -o-transform: scale($val);
    transform: scale($val);
}

@mixin scaleX($val) {
    -webkit-transform: scaleX($val);
    -moz-transform: scaleX($val);
    -ms-transform: scaleX($val);
    -o-transform: scaleX($val);
    transform: scaleX($val);
}

@mixin background($val1, $val2, $val3, $val4) {
    background-image: ($val1);
    background-repeat: ($val2);
    background-position: ($val3);
    background-size: ($val4);
}

@mixin animation-name($val) {
    -webkit-animation-name: ($val);
    -moz-animation-name: ($val);
    -ms-animation-name: ($val);
    -o-animation-name: ($val);
    animation-name: ($val);
}

@mixin animation-duration($val) {
    -webkit-animation-duration: ($val);
    -moz-animation-duration: ($val);
    -ms-animation-duration: ($val);
    -o-animation-duration: ($val);
    animation-duration: ($val);
}

@mixin gradient($color1, $color2, $pos1, $pos2) {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff1010+0,ff8f1a+100 */
    background: $color1;
    /* Old browsers */
    background: -moz-linear-gradient($pos1, $color1 0%, $color2 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient($pos1, $color1 0%, $color2 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to $pos2, $color1 0%, $color2 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='$val1', endColorstr='$val2', GradientType=0);
    /* IE6-9 */
}

@mixin flexbox() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -webkit-flex: $values;
    -ms-flex: $values;
    flex: $values;
}

@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

@mixin align-items($val) {
    -ms-flex-align: ($val);
    -webkit-align-items: ($val);
    -webkit-box-align: ($val);
    align-items: ($val);
}

//---------- Blog Grid Item Size Builder ----------//
@function blog-grid-item-width($container, $columns, $margin) {
    @return floor( ( $container - ( $margin * ( $columns - 1))) / $columns);
}

// Media Screen Queries
@mixin desktop-lg {
    @media (min-width: #{$desktop-lg-width - 1px}) {
        @content;
    }
}

@mixin desktop {
    @media (min-width: #{$desktop-width}) {
        @content;
    }
}

@mixin tablet-real {
    @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$tablet-width}) {
        @content;
    }
}

@mixin phone {
    @media (max-width: #{$phone-width}) {
        @content;
    }
}