// Clearfix
//
// For modern browsers
// 1. The space content is one way to avoid an Opera bug when the
//    contenteditable attribute is included anywhere else in the document.
//    Otherwise it causes space to appear at the top and bottom of elements
//    that are clearfixed.
// 2. The use of `table` rather than `block` is only necessary if using
//    `:before` to contain the top-margins of child elements.
//
// Source: http://nicolasgallagher.com/micro-clearfix-hack/
@mixin clearfix() {
    &:before,
    &:after {
        content: " "; // 1
        display: table; // 2
    }
    &:after {
        clear: both;
    }
}

.text-decoration-none {
    text-decoration: none;
    &:hover,
    &:focus,
    &:active {
        text-decoration: none;
    }
}

.valign-center {
    position: absolute;
    top: 50%;
    @include translateY (-50%);
}

.underline-effect {
    position: relative;
    text-decoration: none;
    color: $theme-color;
    overflow: hidden;
    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 10px;
        left: 0;
        background-color: $theme-color;
        visibility: hidden;
        @include scaleX(0);
        @include transition (all 0.1s ease-in-out 0s)
    }
    &:hover {
        &:before {
            visibility: visible;
            @include scaleX(1);
        }
    }
}