/*===================================
=            Extra Class            =
===================================*/

.clearfix {
    @include clearfix;
}

.center {
    text-align: center;
    >img {
        display: block;
        margin: auto;
    }
}

.bold {
    font-weight: bold;
}

.italic {
    font-style: italic;
}

.clear {
    clear: both;
    display: block;
    font-size: 0px;
    height: 0px;
    line-height: 0;
    width: 100%;
    overflow: hidden;
}

.block {
    display: block;
}


/*----------  FROM CANVAS  ----------*/

.uppercase {
    text-transform: uppercase !important;
}

.lowercase {
    text-transform: lowercase !important;
}

.capitalize {
    text-transform: capitalize !important;
}

.nott {
    text-transform: none !important;
}

.tright {
    text-align: right !important;
}

.tleft {
    text-align: left !important;
}

.fright {
    float: right !important;
}

.fleft {
    float: left !important;
}

.fnone {
    float: none !important;
}

.ohidden {
    position: relative;
    overflow: hidden !important;
}

.line,
.double-line {
    clear: both;
    position: relative;
    width: 100%;
    margin: ($margin-base + 10) 0;
    border-top: 1px solid #EEE;
    &.line-sm {
        margin: $margin-small 0;
    }
}

span.middot {
    display: inline-block;
    margin: 0 5px;
}

.double-line {
    border-top: 3px double #E5E5E5;
}

.emptydiv {
    display: block !important;
    position: relative !important;
}

.allmargin {
    margin: $margin-base !important;
}

.leftmargin {
    margin-left: $margin-base !important;
}

.rightmargin {
    margin-right: $margin-base !important;
}

.topmargin {
    margin-top: $margin-base !important;
}

.bottommargin {
    margin-bottom: $margin-base !important;
}

.clear-bottommargin {
    margin-bottom: -$margin-base !important;
}

.allmargin-sm {
    margin: $margin-small !important;
}

.leftmargin-sm {
    margin-left: $margin-small !important;
}

.rightmargin-sm {
    margin-right: $margin-small !important;
}

.topmargin-sm {
    margin-top: $margin-small !important;
}

.bottommargin-sm {
    margin-bottom: $margin-small !important;
}

.clear-bottommargin-sm {
    margin-bottom: -$margin-small !important;
}

.allmargin-lg {
    margin: $margin-big !important;
}

.leftmargin-lg {
    margin-left: $margin-big !important;
}

.rightmargin-lg {
    margin-right: $margin-big !important;
}

.topmargin-lg {
    margin-top: $margin-big !important;
}

.bottommargin-lg {
    margin-bottom: $margin-big !important;
}

.clear-bottommargin-lg {
    margin-bottom: -$margin-big !important;
}

.nomargin {
    margin: 0 !important;
}

.noleftmargin {
    margin-left: 0 !important;
}

.norightmargin {
    margin-right: 0 !important;
}

.notopmargin {
    margin-top: 0 !important;
}

.nobottommargin {
    margin-bottom: 0 !important;
}

.noborder {
    border: none !important;
}

.noleftborder {
    border-left: none !important;
}

.norightborder {
    border-right: none !important;
}

.notopborder {
    border-top: none !important;
}

.nobottomborder {
    border-bottom: none !important;
}

.noradius {
    @include border-radius(0);
}

.nopadding {
    padding: 0 !important;
}

.noleftpadding {
    padding-left: 0 !important;
}

.norightpadding {
    padding-right: 0 !important;
}

.notoppadding {
    padding-top: 0 !important;
}

.nobottompadding {
    padding-bottom: 0 !important;
}

.noabsolute {
    position: relative !important;
}

.noshadow {
    @include box-shadow(none !important)
}

.hidden {
    display: none !important;
}

.nothidden {
    display: block !important;
}

.inline-block {
    float: none !important;
    display: inline-block !important;
}

.center {
    text-align: center !important;
}

.center-xs {
    @include phone {
        text-align: center !important;
    }
}

.divcenter {
    position: relative !important;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
}

.color {
    color: $theme-color !important;
}

.border-color {
    border-color: $theme-color !important;
}

.nobg {
    background: none !important;
}

.nobgcolor {
    background-color: transparent !important;
}

.bg-theme {
    background-color: $theme-color !important;
}

.bg-secondary {
    background-color: $secondary-color !important;
}

.text-theme {
    color: $theme-color !important;
}

.text-dark {
    color: $dark-color !important;
}

.text-light {
    color: $light-color !important;
}

.text-gray {
    color: $input-form-color !important;
}

.text-gray-dark {
    color: $bs-color-gray-dark !important;
}

.bg-gradient-theme {
    @include gradient(#3197ed, #175da2, top, bottom)
}

.fullscreen {
    min-height: 100vh;
}