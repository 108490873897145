.table-default {
    thead {
        th,
        td {
            font-size: $font-size-h3 - 2;
        }
    }
    tbody {
        td {
            font-size: $font-size-base;
            padding: 3px 5px;
        }
    }
    @include phone {
        thead {
            th,
            td {
                font-size: $font-size-base + 2;
                vertical-align: top;
            }
        }
        tbody {
            td {
                font-size: $font-size-base;
                padding: .5rem;
            }
        }
    }
    &-spacing {
        tbody {
            td {
                padding: 10px 5px;
            }
        }
    }
}

.table-noborder {
    thead,
    tbody,
    tfoot {
        th,
        td {
            border-color: transparent;
        }
    }
}

.table-striped {
    thead {
        tr {
            th,
            td {
                background-color: #f7f7f7;
            }
        }
    }
    tbody {
        tr {
            &:nth-of-type(odd) {
                background-color: #fcfcfc;
            }
            &:nth-of-type(even) {
                background-color: #f9f9f9;
            }
        }
    }
}

.table-std {
    thead {
        >tr {
            >th {
                background-color: $secondary-color;
                color: $light-color;
            }
        }
    }
    tbody {
        >tr {
            >td {
                background-color: $light-color;
            }
        }
    }
}

.table-std-tr-list {
    tr {
        td {
            .table {
                margin-bottom: 0;
            }
        }
    }
}

.table-dataTable {
    margin-top: 1rem;
}

// DATA TABLE
table.dataTable thead th,
table.dataTable thead td {
    vertical-align: middle;
}

.dataTables_wrapper {
    .dataTables_paginate {
        .paginate_button {
            padding: .5rem 0 !important;
            margin: .5rem .5rem;
            width: 40px;
            height: 40px;
            line-height: 1.2;
            text-align: center;
            border-radius: 50% !important;
            font-size: $font-size-h4;
            font-weight: bold;
            background-color: transparent;
            border-color: transparent;
            color: $dark-color;
            text-align: center;
            background: none !important;
            &.current {
                background-color: $bs-color-warning !important;
                border-color: $bs-color-warning !important;
                color: $light-color !important;
            }
            &.disabled {
                opacity: .75;
            }
            &:hover {
                background-color: $bs-color-warning !important;
                border-color: $bs-color-warning !important;
                color: $bs-color-warning !important;
            }
            &.previous,
            &.next {
                width: auto;
                line-height: 1;
                border: none !important;
                &:hover {
                    background-color: transparent !important;
                    border: none !important;
                    color: $bs-color-warning !important;
                }
            }
        }
    }
    .dataTables_info {
        @extend .lead;
        color: $body-color;
        float: none !important;
    }
    .dataTables_paginate {
        float: none !important;
        text-align: center !important;
    }
}

.dataTables_filter {
    float: left !important;
    label {
        color: $theme-color;
        font-size: 18px;
        text-transform: uppercase;
    }
}

.dataTables_length {
    float: right !important;
    label {
        color: $theme-color;
        font-size: 18px;
        text-transform: uppercase;
    }
}