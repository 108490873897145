/*============================
=            LIst            =
============================*/

.list-group-event {
    .list-group-item {
        padding: 1.5rem 0 1.5rem 2rem;
        margin: 1rem 0;
    }
    small {
        font-weight: bold;
    }
    .bordered {
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        padding-left: 30px;
    }
    .btn {
        min-width: 140px;
    }
    .btn-pos-center {
        position: absolute;
        @extend .valign-center;
        left: 2rem;
        right: 2rem;
    }
    .col-flag {
        max-width: 76px;
    }
    &-2 {
        .col-flag {
            margin-right: 15px;
        }
    }
}

.list-group-user {
    .col-flag {
        margin: -0.75rem 0;
        margin-left: -.35rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        background-color: $bs-color-gray;
        color: $light-color;
    }
    .list-group-item {
        margin: 1rem 0;
    }
    form {
        padding-top: 1rem;
    }
}

.list-group-participant {
    .list-group-item {
        background-color: $gray-color;
        margin: 1rem 0;
        border-radius: .25rem;
    }
    .accordion {
        background-color: $light-color;
        margin: 1rem -1.25rem -0.75rem;
    }
    @include phone {
        .text-right {
            text-align: left !important;
            margin-top: .5rem;
        }
        .fa-user {
            float: left;
            position: relative;
            width: 25px;
            overflow: hidden;
            margin-right: .5rem;
        }
        strong {
            float: left;
        }
        .fa-check-circle {
            display: none;
        }
    }
}

.list-indent {
    margin-bottom: 2rem;
    &.nostyle {
        ul,
        ol {
            padding-left: 1.75rem;
            li {
                list-style: none;
            }
        }
    }
    ul,
    ol {
        padding-left: 3rem;
        li {
            margin: 1rem 0;
            color: $theme-color;
            font-size: $font-size-h4;
        }
    }
    @include phone {
        margin-bottom: 1rem;
        &.nostyle {
            ul,
            ol {
                padding-left: 1rem;
            }
        }
        ul,
        ol {
            padding-left: 2rem;
        }
    }
}