//-------------------- Base Colors --------------------//

$white:							#fff;
$black:							#000;

$theme-color:                   #2d4f9d;
$secondary-color:				#ffcd22;
$body-color:                    #828282;
$heading-color:                 $black;
$input-form-color:              #bdbdbd;

$dark-color:                    $black;
$light-color:                   $white;
$gray-color:                    #F5F5F5;
$soft-gray-color:               #f2f2f2;
$soft-grey-color:               #f2f2f2;
$yellow-color:                  #c6cd00;
$disable-color:                 #e9ecef;

$dashboard-bg-color:            #f7f7f7;

// Default Bootstrap Color
$bs-color-blue:                 #007bff;
$bs-color-indigo:               #6610f2;
$bs-color-purple:               #6f42c1;
$bs-color-pink:                 #e83e8c;
$bs-color-red:                  #dc3545;
$bs-color-orange:               #fd7e14;
$bs-color-yellow:               #ffc107;
$bs-color-green:                #28a745;
$bs-color-teal:                 #20c997;
$bs-color-cyan:                 #17a2b8;
$bs-color-white:                #ffffff;
$bs-color-gray:                 #868e96;
$bs-color-gray-dark:            #343a40;
$bs-color-primary:              #007bff;
$bs-color-secondary:            #868e96;
$bs-color-success:              #28a745;
$bs-color-info:                 #17a2b8;
$bs-color-warning:              #ffc107;
$bs-color-danger:               #dc3545;
$bs-color-light:                #f8f9fa;
$bs-color-dark:                 #343a40;

//-------------------- Border --------------------//

$border-color:					$input-form-color;

//-------------------- Line Height --------------------//

$line-height-base:              1.5;
$line-height-content:           1.8;


//-------------------- Margins --------------------//

$margin-base:                   2rem;
$margin-small:                  1rem;
$margin-big:                    3rem;



//-------------------- Font Families --------------------//

$body-font:                     'Roboto', sans-serif;
$heading-font:                  'Roboto Condensed', sans-serif;
$secondary-font:                'Roboto Condensed', sans-serif;

$dashboard-font:                'Lato', sans-serif;


//-------------------- Font Sizes --------------------//

$font-size-base:                14px;
$font-size-h1:                  36px;
$font-size-h2:                  30px;
$font-size-h3:                  24px;
$font-size-h4:                  18px;
$font-size-h5:                  $font-size-base;
$font-size-h6:                  12px;

//-------------------- Media Queries --------------------//

$phone-width: 767px;
$tablet-width: 800px;
$desktop-width: 1024px;
$desktop-lg-width: 1440px;


//-------------------- Transition --------------------//

$ease-in-out-5s: 				all .5s ease-in-out;
$ease-in-out-3s: 				all .3s ease-in-out;
$ease-in-out-2s: 				all .2s ease-in-out;

//-------------------- Box Shadow --------------------//

$box-shadow-default:			5px 7px 15px 0px #ddd;