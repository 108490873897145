// DASHBOARD
.page-dashboard {
    .navbar {
        background-color: $light-color;
        padding: 0;
        .navbar-holder {
            padding: .2rem 0;
        }
        .brand-text {
            text-transform: inherit;
            color: $body-color;
            font-size: $font-size-base;
            strong {
                font-size: inherit;
            }
        }
        .brand-logo-text {
            margin-bottom: 0;
            font-size: $font-size-h3 - 2;
            color: #A2A718;
            span {
                color: $body-color;
            }
        }
    }
    .navbar-header {
        margin-left: -3rem;
        a.menu-btn {
            @extend .text-decoration-none;
            padding: 1rem !important;
            background-color: darken($theme-color, 5%);
            .fa {
                position: relative;
                top: 3px;
            }
        }
    }
    @include phone {
        .navbar-header {
            margin-left: -1rem;
        }
    }
}

.side-navbar {
    @include gradient(lighten($theme-color, 10%), darken($theme-color, 5%), top, bottom);
    .main-menu {
        min-height: calc(100vh - 150px);
        padding-bottom: 120px;
    }
    .main-menu-footer {
        padding: 25px;
        position: absolute;
        bottom: 0;
        width: 100%;
        &-inner {
            position: absolute;
            bottom: 20px;
        }
        .sign-out {
            color: $light-color;
            display: inline-block;
            margin-bottom: 15px;
            @extend .text-decoration-none;
            &:hover {
                opacity: .75;
            }
        }
        p {
            margin-bottom: 0;
        }
    }
    &.shrink {
        li {
            ul {
                padding: 0;
            }
        }
        .main-menu-footer {
            padding: 6px;
            font-size: 80%;
            text-align: center;
            .sign-out {
                img {
                    display: block;
                    margin: 0 auto .25rem;
                }
            }
        }
    }
    .sidenav-header {
        background-color: transparent;
        img {
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 5px;
        }
        span {
            color: $light-color;
            font-weight: 100;
        }
    }
    li {
        a[data-toggle="collapse"] {
            &::before {
                content: '\f067';
            }
        }
        a[aria-expanded="true"] {
            &::before {
                content: '\f068';
            }
        }
        a {
            font-size: $font-size-base - 1;
            padding: 12px 25px;
            &:hover,
            &:focus,
            &[aria-expanded="true"] {
                background-color: rgba($light-color, .15);
            }
        }
        ul {
            background-color: darken($theme-color, 5%);
            padding: 10px 10px 10px 25px;
            li {
                a {
                    font-size: $font-size-base - 2 !important;
                    padding: 10px;
                }
            }
            &.collapse {
                li {
                    a {
                        border: none;
                        &:hover,
                        &:focus {
                            background-color: transparent;
                            color: $secondary-color;
                        }
                    }
                }
            }
        }
    }
    @include phone {
        li {
            ul {
                padding: 0;
            }
        }
    }
}