/*==============================
=            Layout            =
==============================*/


/*----------  CONTAINER  ----------*/

.container-full {
    margin-left: auto;
    margin-right: auto;
    padding-left: 0;
    padding-right: 0;
}


/*----------  SECTION  ----------*/

.section-panel {
    padding-top: 2em;
    padding-bottom: 2em;
    position: relative;
}


/*----------  Display Table  ----------*/

.display-table {
    display: table;
    width: 100%;
    .display-table-cell {
        display: table-cell;
        vertical-align: middle;
    }
    &.valign-top {
        .display-table-cell {
            vertical-align: top;
        }
    }
    &.valign-middle {
        .display-table-cell {
            vertical-align: middle;
        }
    }
    &.valign-bottom {
        .display-table-cell {
            vertical-align: bottom;
        }
    }
}


/*----------  GRID  ----------*/

.grid-nopadding {
    div[class*="col-"] {
        padding: 0;
    }
}

.main-wrapper {
    @extend .clearfix;
    &.fullscreen {
        @include phone {
            min-height: auto;
            height: auto;
        }
    }
}

.page-dashboard {
    background-color: $dashboard-bg-color;
    // width: calc(100% - 250px);
    .boxes-panel {
        margin: 1rem auto;
    }
}

.page-content {
    padding-left: 40px;
    padding-right: 40px;
    // Nav
    .nav-std {
        margin-left: -40px;
        margin-right: -40px;
        padding-left: 40px;
        padding-right: 40px;
    }
    @include phone {
        padding-left: 20px;
        padding-right: 20px;
        // Nav
        .nav-std {
            margin-left: -20px;
            margin-right: -20px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}