input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.dashboard {
    .form-control {
        font-family: $dashboard-font;
    }
}

.asterisk {
    color: red;
}

label {
    font-size: $font-size-base - 2;
    font-weight: bold;
}

.form-control,
.btn {
    &:disabled,
    &.disabled {
        pointer-events: none !important;
        &:hover {
            cursor: not-allowed;
        }
    }
}

.form-control {
    font-size: $font-size-base;
    color: $input-form-color;
    border-radius: 6px;
    height: 45px;
    @include transition(all .25s ease-in);
    &:focus {
        outline: none;
        box-shadow: none;
        border-color: $input-form-color;
    }
}

select.form-control:not([size]):not([multiple]) {
    height: 45px;
}

textarea {
    &.form-control {
        height: auto;
    }
}

input.form-control,
select.form-control {
    border-radius: 6px;
}

.input-group {
    .input-group-text {
        border-right: none;
        position: relative;
        &::after {
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 50%;
            @include translateY(-50%);
            height: 20px;
            width: 2px;
            background-color: $border-color;
        }
    }
    .form-control {
        border-left: none;
    }
}

.input-group-text {
    &.disabled {
        background-color: $disable-color;
    }
    background-color: transparent;
    color: $input-form-color;
    height: 45px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include transition(all .25s ease-in);
    .fa {
        font-size: 1.5rem;
    }
}

.form-group {
    margin-top: 1rem;
    label {
        color: $input-form-color;
        @include transition(all .25s ease-in);
    }
    &.focus,
    &.filled {
        label {
            color: $theme-color;
        }
        .fa {
            color: $theme-color;
        }
        .form-control,
        .input-group-text {
            border-color: lighten($theme-color, 10%);
            color: $dark-color;
        }
    }
    @include phone {
        margin-top: 0;
    }
}

.form-flip {
    [class*="col"] {
        &:first-child {
            padding-right: 3rem;
            border-right: 1px solid $border-color;
        }
        &:last-child {
            padding-left: 3rem;
        }
    }
    @include phone {
        &:first-child {
            padding-right: 15px;
            border-right: none;
        }
        &:last-child {
            padding-left: 15px;
        }
    }
}

.date-time {
    .bridge {
        position: absolute;
        left: 0;
        right: 0;
        text-align: center;
        bottom: 30px;
        width: 30px;
        margin: auto;
    }
}

// SELECT2
$select2-height: 45px;
.select2-container {
    width: 100% !important;
    height: $select2-height;
    text-align: left;
    margin: .25rem;
    .selection {
        display: block;
        font-size: $font-size-base;
        color: $input-form-color;
        border: 1px solid $input-form-color;
        ;
        border-radius: 6px;
        display: block;
        @include transition(all .25s ease-in);
        &:focus {
            outline: none;
            box-shadow: none;
            border-color: lighten($theme-color, 10%);
        }
    }
}

.select2-container--default .select2-selection--single {
    border-color: transparent;
}

.select2-container .select2-selection--single,
.select2-container--default .select2-selection--single .select2-selection__arrow {
    height: $select2-height;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: $select2-height;
    padding: 0 1rem;
}

.select2-search--dropdown .select2-search__field {
    height: 30px;
}

.select2-container--open .select2-dropdown {
    left: -3px;
}

.select2-results {
    color: $dark-color;
}

.input-group {
    &-select2-dropdown {
        margin: .25rem;
    }
    .select2-container {
        width: 50% !important;
        flex: 1;
        margin: 0;
        border-left: none;
        overflow: hidden;
        .selection {
            height: auto;
            border-left: none;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        .select2-selection--single {
            height: $select2-height - 2;
            border: none;
        }
    }
}

.dataTables_filter,
.dataTables_length {
    margin-bottom: 1rem;
    select,
    input {
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        font-size: $font-size-base;
        color: $input-form-color;
        border-radius: 6px;
        height: 45px;
        @include transition(all .25s ease-in);
        &:focus {
            outline: none;
            box-shadow: none;
            border-color: $input-form-color;
        }
    }
}