/*============================
=            Card            =
============================*/

.card-list {
    margin: 2rem 0;
}

.card-std {
    @include transition(all .25s ease);
    overflow: hidden;
    .card-body {
        padding: .75rem 1.25rem;
    }
    .img {
        @include background(inherit, no-repeat, center, cover);
        height: 80px;
    }
    a {
        @extend .text-decoration-none;
    }
    &:hover {
        box-shadow: $box-shadow-default;
    }
}