/**
 *
 * APP SCSS
 *
 */


/*----------  HELPERS  ----------*/

@import 
	'helpers/_variables.scss',
	'helpers/_mixins.scss',
	'helpers/_extends.scss',
	'helpers/_extra-class.scss';

/*----------  BASE  ----------*/

@import 
	'base/_normalize.scss',
	'base/_typography.scss';

/*----------  LAYOUT  ----------*/

@import 
	'layout/_layout.scss',
	'layout/_header.scss',
	'layout/_footer.scss',
	'layout/_sidebar.scss',
	'layout/_responsive.scss';

/*----------  COMPONENTS  ----------*/

@import 
	'components/_button.scss',
	'components/_icon.scss',
	'components/_dropdown.scss',
	'components/_form.scss',
	'components/_table.scss',
	'components/_banner.scss',
	'components/_slider.scss',
	'components/_list.scss',
	'components/_card.scss',
	'components/_thumbnail.scss',
	'components/_wysiwyg.scss',
	'components/_popup.scss',
	'components/_toggle.scss',
	'components/_accordion.scss',
	'components/_element.scss';

/*----------  PAGES  ----------*/

// @import 
// 	'pages/_homepage.scss',
// 	'pages/_detailpage.scss';


/*----------  THEMES  ----------*/
