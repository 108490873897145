/*==============================
=            Toggle            =
==============================*/

.toggle {
    display: block;
    position: relative;
    margin: 0 0 20px 0;
}

.toggle {
    .togglet,
    .toggleta {
        display: block;
        position: relative;
        line-height: 24px;
        padding: 0 0 0 24px;
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: #444;
        cursor: pointer;
    }
    .togglet i {
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
    }
    .toggleta {
        font-weight: bold;
    }
}

.toggle {
    .togglet i.toggle-open,
    .toggleta i.toggle-closed {
        display: none;
    }
    .toggleta i.toggle-open {
        display: block;
    }
    .togglec {
        display: block;
        position: relative;
        padding: 10px 0 0 24px;
    }
}


/* Toggle - with Title Background
-----------------------------------------------------------------*/

.toggle {
    &.toggle-bg {
        .togglet,
        .toggleta {
            background-color: #EEE;
            line-height: 44px;
            padding: 0 0 0 36px;
            @include border-radius(2px);
        }
        .togglet {
            i {
                left: 14px;
                line-height: 44px;
            }
        }
        .togglec {
            padding: 12px 0 0 36px;
        }
    }
}


/* Toggle - Bordered
-----------------------------------------------------------------*/

.toggle {
    &.toggle-border {
        border: 1px solid #CCC;
        @include border-radius(4px);
        .togglet {
            i {
                left: 14px;
                line-height: 44px;
            }
        }
        .togglec {
            padding: 0 15px 15px 36px;
        }
        .togglet,
        .toggleta {
            line-height: 44px;
            padding: 0 15px 0 36px;
        }
    }
}
