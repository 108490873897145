@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Roboto:300,500,700');
@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700');
body {
    font-family: $body-font;
    line-height: $line-height-base;
    font-size: $font-size-base;
    color: $body-color;
    &.dashboard {
        font-family: $dashboard-font;
        font-weight: 400;
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            font-family: $dashboard-font;
            font-weight: bold;
        }
    }
}


/*----------  Heading  ----------*/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font;
}

dl,
dt,
dd,
ol,
ul,
li {
    padding: 0;
}

a {
    color: $theme-color;
    @include transition(all .25s);
    &:hover,
    &:focus {
        color: $secondary-color;
    }
}

.lead {
    font-size: 1.1rem;
    font-weight: normal;
}

strong {
    .lead,
    &.lead {
        font-weight: bold;
    }
}

.intro-section {
    @extend .clearfix;
    h1 {
        color: $theme-color;
        font-weight: bold;
    }
}

.title-section {
    font-size: $font-size-h3 - 2;
    color: $secondary-color;
    margin: 1rem 0 .5rem;
}

.extra-large {
    font-size: $font-size-h1 + 4;
    @include phone {
        font-size: $font-size-h2;
    }
}