/*----------  Block Section  ----------*/

::selection {
    background: $theme-color;
    color: #FFF;
    text-shadow: none;
}

::-moz-selection {
    background: $theme-color;
    /* Firefox */
    color: #FFF;
    text-shadow: none;
}

::-webkit-selection {
    background: $theme-color;
    /* Safari */
    color: #FFF;
    text-shadow: none;
}

:active,
:focus {
    outline: none !important;
}

.breadcrumb {
    background-color: transparent;
    padding: 0;
    .breadcrumb-item+.breadcrumb-item::before {
        content: '>'
    }
}

.boxes-panel {
    position: relative;
    background-color: $light-color;
    margin: 2rem auto;
    max-width: 1080px;
    border-radius: .5rem;
    box-shadow: 0px 0px 15px #ccc;
    &.full {
        max-width: 100%;
    }
    &-small {
        max-width: 475px;
    }
    a {
        @extend .text-decoration-none;
    }
}

.boxes-panel-head {
    padding: 1rem 2rem;
    border-bottom: 1px solid lighten($border-color, 10%);
    .title-text {
        color: $theme-color;
        font-size: $font-size-h3 - 2;
        font-weight: bold;
        margin: .15rem 0;
        &.lg {
            font-size: $font-size-h1;
        }
    }
    .subtitle-text {
        color: $bs-color-green;
        margin-bottom: 0;
    }
    img {
        max-width: 100%;
    }
    .step-bar {
        margin: 0 -20px 0 auto;
    }
    @include phone {
        text-align: center;
        img {
            margin-bottom: .75rem;
        }
        .step-bar {
            margin: 1.25rem 0 0;
            width: 100%;
        }
    }
}

.boxes-panel-body {
    padding: 1rem 3rem;
    min-height: 360px;
    @include phone {
        padding: 1rem 1.5rem;
        min-width: 0;
    }
}

.boxes-panel-foot {
    padding: 1em 1rem;
    background-color: $soft-grey-color;
    border-bottom-left-radius: .5rem;
    border-bottom-right-radius: .5rem;
    .title-text {
        color: $theme-color;
        font-size: $font-size-h4;
        font-weight: bold;
        margin-bottom: 0;
    }
}

hr {
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include phone {
        margin-top: 1.25rem;
        margin-bottom: 1.25rem;
    }
}

// NAV
.nav-std {
    border-bottom: 1px solid $bs-color-gray;
    .nav-link {
        font-size: $font-size-h4;
        font-weight: normal;
        color: $bs-color-gray;
        padding: .75rem .5rem;
        margin: 0 .5rem;
        position: relative;
        &:hover {
            color: $theme-color;
        }
        &.active {
            font-weight: bold;
            color: darken($theme-color, 15%);
            &::after {
                content: '';
                display: block;
                height: 4px;
                width: 100%;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -2px;
                background-color: $bs-color-warning;
            }
        }
        &.noborder {
            &::after {
                display: none;
            }
        }
    }
}

// Panel
.panel-filter {
    margin: 1.5rem 0;
    label {
        color: $theme-color;
        font-size: $font-size-h4;
    }
}

// Flag
.col-flag {
    margin: -1.5rem 15px -1.5rem -18px;
    &-workshop {
        background-color: #1BBC9B;
    }
    &-munas {
        background-color: #F39C11;
    }
    &-rakernas {
        background-color: #297FB8;
    }
    &-rakor {
        background-color: #8E57AB;
    }
    &-bimtek {
        background-color: #AD5F47;
    }
    &-hotel {
        background-color: #308696;
    }
    .flag {
        text-align: center;
        span {
            color: $light-color;
            min-width: 120px;
        }
    }
}

.flag {
    span {
        font-size: $font-size-h4;
        @include rotate(-90deg);
        position: absolute;
        top: 42%;
        left: -30%;
        font-weight: bold;
        display: block;
    }
}

// Pagination
.paging {
    .page-item {
        &.active {
            .page-link {
                background-color: $bs-color-warning;
                border-color: $bs-color-warning;
            }
        }
    }
    .page-link {
        padding: 0;
        margin: .5rem .5rem;
        width: 40px;
        height: 40px;
        line-height: 35px;
        text-align: center;
        border-radius: 50% !important;
        font-size: $font-size-h4;
        font-weight: bold;
        background-color: transparent;
        border-color: transparent;
        color: $dark-color;
        &:hover {
            color: $bs-color-warning;
        }
    }
}

// Progress Bar
.step-bar {
    margin-bottom: 0;
    @extend .clearfix;
    position: relative;
    width: 300px;
    margin: auto;
    /*CSS counters to number the steps*/
    counter-reset: step;
    &::before {
        content: '';
        width: calc(100% - 100px);
        height: 4px;
        background: $soft-grey-color;
        position: absolute;
        top: 15px;
        left: 0;
        right: 0;
        display: block;
        margin: auto;
    }
    li {
        list-style-type: none;
        color: $body-color;
        text-transform: uppercase;
        font-size: $font-size-base;
        font-weight: normal;
        width: calc(100% / 3);
        float: left;
        position: relative;
        text-align: center;
        z-index: 0;
        &::before {
            content: counter(step);
            counter-increment: step;
            width: 30px;
            height: 30px;
            line-height: 30px;
            display: block;
            font-size: 20px;
            color: $light-color;
            border-radius: 50%;
            background-color: $bs-color-gray;
            margin: 0 auto;
            margin-bottom: .5rem;
        }
        &::after {
            content: '';
            width: 40px;
            height: 40px;
            line-height: 40px;
            display: block;
            position: absolute;
            background-color: $bs-color-gray;
            left: 0;
            right: 0;
            margin: auto;
            top: -5px;
            border-radius: 50%;
            opacity: .25;
            z-index: -1;
        }
        &.done {
            &::before,
            &::after {
                background: $bs-color-green;
                color: white;
            }
            &::before {
                font-family: FontAwesome;
                content: '\f00c';
            }
        }
        &.active {
            &::before,
            &::after {
                background: darken($bs-color-blue, 20%);
                color: white;
            }
        }
    }
}

// QRCODE
.panel-qrcode {
    width: 105px;
    display: block;
    margin: 1rem auto 0 0;
    text-align: center;
    img {
        max-width: 100%;
    }
}