/*===============================
=            Wysiwyg            =
===============================*/

.wysiwyg-content {
    line-height: $line-height-base;
    h1 {
        font-size: $font-size-h1;
    }
    h2 {
        font-size: $font-size-h2;
    }
    h3 {
        font-size: $font-size-h3;
    }
    h4 {
        font-size: $font-size-h4;
    }
    h5 {
        font-size: $font-size-h5;
    }
    h6 {
        font-size: $font-size-h6;
    }
    p {
        margin-bottom: 15px;
    }
    strong {
        font-weight: bold;
    }
    em {
        font-style: italic;
    }
    u {
        text-decoration: underline;
    }
    a {
        color: $theme-color;
    }
    img {
        margin-top: 10px;
        margin-bottom: 30px;
        max-width: 100%;
        height: auto;
    }
    ol,
    ul {
        margin-bottom: 20px;
        padding-left: 20px;
        li {
            margin-bottom: 5px;
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                display: inline;
                margin: 0;
            }
        }
    }
    p {
        color: $body-color;
    }
    table {
        width: 100% !important;
        max-width: 100%;
        line-height: 1.5;
        > thead > tr > th,
        > tbody > tr > th,
        > tfoot > tr > th,
        > thead > tr > td,
        > tbody > tr > td,
        > tfoot > tr > td {
            border: 1px solid #ccc;
            padding: 15px 30px;
            vertical-align: top;
            border: 1px solid #c1c2c2;
        }
        > thead > tr > th {
            font-weight: bold;
            background-color: #333;
            color: #fff;
        }
    }
    q {
        padding: 10px 20px;
        margin: 0 0 20px;
        font-size: 17.5px;
        border-left: 5px solid #eee;
        font-style: italic;
    }
    @include phone {
        h1 {
            font-size: 30px;
        }
        h2 {
            font-size: 24px;
        }
        h3 {
            font-size: 18px;
        }
        h4 {
            font-size: 14px;
        }
        h5 {
            font-size: 10px;
        }
        h6 {
            font-size: 8px;
        }
    }
}

.wysiwyg-content.paragraph-indent {
    p {
        text-indent: 2em;
    }
}
