.btn {
    font-family: $dashboard-font;
    margin: .25rem;
    padding: .65rem 1.5rem;
    font-size: $font-size-base - 2;
    font-weight: bold;
    box-shadow: none !important;
    &.block {
        width: 100%;
    }
}

.btn-create {
    margin-top: 1.25rem;
}

.btn-group-toggle {
    .btn {
        margin: 0;
        height: 45px;
        padding-top: 0;
        padding-bottom: 0;
        line-height: 45px;
        &:hover {
            background-color: $disable-color !important;
            color: $theme-color !important;
        }
        &.active {
            background-color: $disable-color !important;
            color: $theme-color !important;
            &.disabled {
                background-color: $disable-color;
                color: $input-form-color !important;
                opacity: 1;
            }
        }
    }
}

.btn-circle {
    width: 25px;
    height: 25px;
    line-height: 25px;
    padding: 0;
    border-radius: 50%;
}