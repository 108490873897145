/*=================================
=            Accordion            =
=================================*/

.accordion {
    margin-bottom: 20px;
    @extend .clearfix;
}

.acctitle,
.acctitlec {
    display: block;
    position: relative;
    line-height: 24px;
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    color: #444;
    cursor: pointer;
    border-top: 1px dotted #DDD;
    padding: 10px 0 10px 20px;
    height: 50px;
}

.acctitlec {
    cursor: auto;
}

.acctitle {
    &:first-child {
        border-top: none;
    }
    i {
        position: absolute;
        top: 0;
        left: 0;
        width: 14px;
        text-align: center;
        font-size: 14px;
        line-height: 44px;
    }
    .fa {
        float: right;
        color: $white;
        font-size: $font-size-base + 6;
        position: relative;
        top: 5px;
    }
    .fa-plus {
        display: inline-block;
    }
    .fa-minus {
        display: none;
    }
    &.acctitlec {
        .fa-plus {
            display: none;
        }
        .fa-minus {
            display: inline-block;
        }
    }
}

.acctitle i.acc-open,
.acctitlec i.acc-closed {
    display: none;
}

.acctitlec i.acc-open {
    display: block;
}

.acc_content {
    position: relative;
    padding: 0 0 15px 20px;
}


/* Accordion - with Title Background
-----------------------------------------------------------------*/

.accordion {
    &.accordion-bg {
        .acctitle,
        .acctitlec {
            background-color: rgba($white, .3);
            line-height: 50px;
            padding: 10px 20px 10px 36px;
            margin-bottom: 5px;
            border-top: 0;
            border-radius: 15px;
        }
        .acctitle {
            i {
                left: 14px;
                line-height: 50px;
            }
        }
        .acc_content {
            padding: 10px 0 30px 36px;
            color: $white;
        }
    }
}


/* Accordion - Bordered
-----------------------------------------------------------------*/

.accordion {
    &.accordion-border {
        border: 1px solid #DDD;
        @include border-radius(4px);
        .acctitle,
        .acctitlec {
            border-color: #CCC;
            line-height: 44px;
            padding: 0 15px 0 36px;
        }
        .acctitle {
            i {
                left: 14px;
                line-height: 44px;
            }
        }
        .acc_content {
            padding: 0 15px 15px 36px;
        }
    }
}


/* Accordion - Large
-----------------------------------------------------------------*/

.accordion {
    &.accordion-lg {
        .acctitle {
            font-size: 23px;
            line-height: 44px;
            padding-left: 30px;
            font-weight: 400;
            i {
                width: 20px;
                font-size: 20px;
                line-height: 64px;
            }
        }
        .acc_content {
            padding: 10px 0 30px 30px;
        }
    }
}
